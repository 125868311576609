const socialLinks = {
  email: 'hello@defraylabs.com',
  // telegramInvite: 'https://t.me/joinchat/zm9bhjrchdo1MTA0',
  // invites to sovryn-lite channel of defray community server
  discordInvite: 'https://discord.gg/HrEQPZncNT',
  githubRepository: 'https://github.com/defray-labs/sovryn-lite-ui',
  twitterUrl: 'https://twitter.com/DefrayLabs',
};

export default socialLinks;
